import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import clsx from 'clsx';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { connect, useDispatch } from 'react-redux';
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { token } from './api/apiUtils';
import './common.scss';
import LoadLazyComponent from './components/LoadLazyComponent';
import OrientationPopUp from './components/OrientationPopUp';
import SpinnerWrapper from './componentsV2/common/SpinnerWrapper';
import Layout from './componentsV2/Layout';
import ErrorBoundary from './componentsV2/ErrorBoundary/ErrorBoundary';
import config from './config/config';
import './css/common.css';
import { loadUserDetail } from './redux/userDetail/userDetailAction';
import { getPageTitleAndAnalytics, isAuthenticated } from './utils';
import {
  Initialize_Amplitude,
  Set_UserId_Amplitude
} from './utils/amplitudeAnalytics';
import {
  AnalyticsPageView,
  sendAnalytics,
  ViewUserActivityAnalytics
} from './utils/analyticsUtils';
import { acceptTermsAndCondition } from './api/useDetailApi';
import 'tippy.js/dist/tippy.css';
import useQuery from './hooks/useQuery';
import AppRoute from './AppRoute';
import * as Sentry from '@sentry/react';
import { setAnalyticsKey } from './redux/Analytics/analyticsAction';

// mui V5 Theme for TextField
const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '13px',
          top: 2
        }
      },
      styleOverrides: {
        shrink: ({ ownerState, theme }) => ({
          ...(ownerState.shrink && {
            fontSize: '1rem !important',
            top: '-1 !important'
          })
        })
      }
    }
  }
});

// Route Components
const Login = lazy(() =>
  LoadLazyComponent(() => import('./pages/Login/Login'))
);

const ReadSheet = lazy(() =>
  LoadLazyComponent(() => import('./pages/Quotation/Quotation'))
);

const TermsAndConditions = lazy(() =>
  LoadLazyComponent(() => import('./pages/TermsAndCondition'))
);

/**
 * SecretRouter component displays the protected route content if the user is authenticated,
 * otherwise, it redirects the user to the login page with a toast notification.
 *
 * @param {function} navigate - A function to navigate to different routes in the application.
 * @returns {JSX.Element} - If the user is authenticated, it returns the content of the protected route.
 *                          If not authenticated, it redirects the user to the login page with a state
 *                          containing the previous location to redirect back after successful login.
 */
export const SecretRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (isAuthenticated() === true) {
    return <Outlet />;
  }
  toast.info('Please login to view this page ...', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true
  });
  return navigate('/login', { state: { prevLocation: location.pathname } });
};

const App = ({ loadUserDetail, userDetail }) => {
  const maintenancePage = false;
  const [modalActive, setModalActive] = useState(false);
  const [appLoading, setAppLoading] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [query] = useQuery();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    AnalyticsPageView(location.pathname + location.search);
    if (new RegExp('^/order/[0-9]+/$').test(location.pathname)) {
      const url = window.location.href.replace('/order/', '/orders/');
      window.location.href = url;
    }
  }, [location]);

  const tokenV2 = token();

  const { analyticsKey, analyticsTitle } = useMemo(() => {
    return getPageTitleAndAnalytics(location.pathname, query);
  }, [location.pathname, query]);

  useEffect(() => {
    if (
      !tokenV2 ||
      (Object.keys(userDetail).length > 0 && !userDetail.is_staff)
    ) {
      ReactGA.initialize(`${config.GA_ACCESS_CODE}`);
      ReactGA.ga('require', 'ec');
      AnalyticsPageView(window.location.pathname + window.location.search);
    }
    if (Object.keys(userDetail).length > 0 && !userDetail.is_staff) {
      const UserName_Id = `${userDetail.name}_${userDetail.id}`;
      if (isAuthenticated() === true) {
        ViewUserActivityAnalytics(UserName_Id);
      }
    }
    if (Object.keys(userDetail).length > 0) {
      Sentry.setUser({
        id: userDetail.id,
        email: userDetail.email,
        name: userDetail.name,
        mobile: userDetail.mobile,
        company_name: userDetail.customer?.company_name
      });
    }
  }, [userDetail]);

  useEffect(() => {
    const handleBeforeInstallPrompt = e => {
      console.log('event', e);
      e.userChoice.then(choiceResult => {
        ReactGA.ga('send', 'event', 'A2H', choiceResult.outcome);
      });
    };

    (async () => {
      try {
        if (tokenV2) {
          await loadUserDetail();
        }
      } catch (error) {
        console.error('Failed to load user details:', error);
      } finally {
        setAppLoading(false);
      }

      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    })();

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);
  /* Amplitude Integration */

  useEffect(() => {
    (async () => {
      if (Object.keys(userDetail).length > 0) {
        await Initialize_Amplitude(userDetail);
      }
    })();
  }, [userDetail]);

  useEffect(() => {
    Set_UserId_Amplitude(userDetail);
  }, [userDetail]);

  useEffect(() => {
    dispatch(setAnalyticsKey(analyticsKey));
    sendAnalytics({
      defaultName: `${analyticsKey}_PageOpen`,
      defaultDetails: `Opened ${location.pathname} Page`
    });
    document.title = analyticsTitle;
  }, [location.pathname]);

  window.addEventListener('orientationchange', function () {
    let screenOrientation = window.orientation;
    if (screenOrientation && screenOrientation !== 0) {
      setModalActive(true);
    } else {
      setModalActive(false);
    }
  });

  if (maintenancePage)
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <img src='https://i.pinimg.com/originals/4e/e5/ca/4ee5ca990c4a7ec5ff5207719caa2e5c.png' />
      </div>
    );

  if (appLoading) return <SpinnerWrapper />;
  const handleAcceptTerms = () => {
    acceptTermsAndCondition();
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={clsx(tokenV2 && 'app')}>
          <OrientationPopUp modalActive={modalActive} />
          <ToastContainer autoClose={7000} />

          <Layout>
            {/* FULL SCREEN SPINNER WRAPPER */}
            <Suspense fallback={<SpinnerWrapper />}>
              <ErrorBoundary>
                {userDetail.is_terms_accepted === false && openModal ? (
                  <TermsAndConditions
                    open={openModal}
                    setOpen={setOpenModal}
                    handleClick={handleAcceptTerms}
                  />
                ) : null}
                <Routes>
                  <Route path='/login' element={<Login />} />
                  {/* <Route path='/' element={<Navigate to={'/dashboard/'} />} /> */}
                  {/* <Route path='/OTPlogin' element={<OTPLogin />} /> */}
                  <Route exact path='/quotation/:id' element={<ReadSheet />} />
                </Routes>

                {/* BODY WIDTH SPINNER WRAPPER */}
                <Suspense fallback={<SpinnerWrapper />}>
                  <AppRoute />
                </Suspense>
              </ErrorBoundary>
            </Suspense>
          </Layout>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.apiCallsInProgress > 0,
    userDetail: state.userDetail
  };
}

const mapDispatchToProps = {
  loadUserDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
