import React, { lazy } from 'react';
import { Slug } from './permissionConstants';
import {
  ActiveGradeIcon,
  InactiveGradeicon
} from '../../assets/icons/modifiedIcons';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import MaterialTestHeader from '../../componentsV2/Layout/Header/MaterialTest';

const CastingReport = lazy(() =>
  LoadLazyComponent(() => import('../../pages/CastingReport/index'))
);

export const CastingReportRoutes = {
  name: 'Casting Report',
  can_view: [Slug.MATERIAL_TEST],
  child_routes: [
    {
      name: 'Material Test',
      icon: InactiveGradeicon,
      active_icon: ActiveGradeIcon,
      component: <CastingReport />,
      header: () => <MaterialTestHeader />,
      permission: [],
      is_superuser: true,
      is_staff: true,
      slug: Slug.MATERIAL_TEST,
      path: '/materialtest/*',
      matchRoutes: '^/materialtest(?:/.*)?$'
    }
  ]
};
