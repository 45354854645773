import { lazy } from 'react';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import {
  ActiveGradeIcon,
  InactiveGradeicon
} from '../../assets/icons/modifiedIcons';
import { Slug } from './permissionConstants';
import ProductionPlanHeader from '../../componentsV2/Layout/Header/ProductionPlanHeader';

const ProductionPlan = lazy(() =>
  LoadLazyComponent(() => import('../../pages/ProductionPlan/index'))
);

export const production_plan_route = {
  name: 'PRODUCTION PLAN',
  can_view: [Slug.PRODUCTION_PLAN],
  child_routes: [
    {
      name: 'Production Plan',
      icon: InactiveGradeicon,
      active_icon: ActiveGradeIcon,
      component: <ProductionPlan />,
      header: () => <ProductionPlanHeader />,
      permission: [],
      slug: Slug.PRODUCTION_PLAN,
      path: 'productionplan/*',
      matchRoutes: '^/productionplan(?:/.*)?$',
      is_superuser: true,
      is_staff: true
    }
  ]
};
